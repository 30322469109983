import React from "react";
import { Row, Col, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import coinlogo from "../logos/coinlogo.svg";
import ExportExcel from "./ExportExcel-closed";

const RelatoriosHeader = (props) => {

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Inclui apenas o volume dos pares com BRL (R$ Real Brasileiro).
        </Tooltip>
    );

    return (
        <Row className="mt-0 px-2 bg-light w-100 border-bottom py-3">
            <Col sm={4} className="text-center">
                <svg className="coinlogo mb-1" role="img" aria-labelledby="title">
                    <title id="title">{props.symbol}</title>
                    <use href={coinlogo + "#" + (props.symbol.slice(0, -3)).toLowerCase() + "-logo"}></use>
                </svg>
                <div className="h5 align-middle mx-2">{props.symbol === "BRLBRL" ? 'Todas as criptomoedas' : props.symbol}</div>
                <p className="h5 text-dark monthcase">{!props.month ? 'Últimos 12 meses' : new Date(props.xaxistime[1]).toLocaleString('pt-BR', { month: 'long', year: 'numeric' })}</p>
            </Col>
            <Col sm={4} lg={3}>
                <ListGroup horizontal className='border-bottom my-2'>
                    <ListGroup.Item className="me-auto border-0 h6 text-muted">Volume {!props.month ? 'anual ' : 'mensal '}
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}>
                            <span className="badge rounded-pill bg-secondary text-white" style={{ fontSize: "70%" }}>i</span>
                        </OverlayTrigger>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0 h6">{props.rawreport.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        <div className="text-muted text-end">{props.symbol !== 'BRLBRL' ? props.rawreport.reduce((a, e) => a + e.Volume, 0).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 0 }) + ' ' + props.symbol.slice(0, -3) : ''}</div></ListGroup.Item>
                </ListGroup>
                <ListGroup horizontal className='border-bottom my-2'>
                    <ListGroup.Item className="me-auto mb-0 border-0 h6 text-muted">Número de corretoras</ListGroup.Item>
                    <ListGroup.Item className="border-0 mb-0 h6">{props.series.length}</ListGroup.Item>
                </ListGroup>
            </Col>
            <Col className="my-auto text-center" sm={4}>
                <ExportExcel symbol={props.symbol} month={props.month} rawreport={props.rawreport} xaxistime={props.xaxistimeUTC} />
            </Col>
        </Row>
    )
}

export default RelatoriosHeader