import React from "react";
import SymbolMarket from "./SymbolMarket";
import Main from './Main';
import Metodologia from './Metodologia';
import Contato from './Contato';
import Graficos from './Graficos';
import Widget from './Widget';
import Assine from './Assine';
import Terms from './Terms';
import ExchangeSummary from './ExchangeSummary';
import Dca from './Dcacripto';
import { Routes, Route, Outlet } from "react-router-dom";
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import LivecoinsHeader from "./components/Livecoins/LivecoinsHeader";
import Relatorios from './components/Relatorios';
import Exchanges from './Exchanges';
import RelatoriosClosed from './components/restricted/Relatorios-closed';
import 'fad-react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ScrollToTopNavigation from "./components/ScrollToTopNavigation";
import usePageTracking from "./utils/withTracker";
import ScrollToTop from "./components/ScrollToTop";

function App() {
    usePageTracking();

    return (
        <>
            <Routes>
                <Route path='*' element={<NotFound />} />
                <Route path="/" element={<Layout />}>
                    <Route index element={<Main />} />
                    <Route path="/metodologia" element={<Metodologia />} />
                    <Route path="/contato" element={<Contato />} />
                    <Route path="/widget" element={<Widget />} />
                    <Route path="/relatorios" element={<Relatorios />} />
                    <Route path="/graficos" element={<Graficos />} />
                    <Route path="/assine" element={<Assine />} />
                    <Route path="/termos" element={<Terms />} />
                    <Route path="/DCA" element={<Dca />} />
                    <Route path="/l1v3c01ns" element={<RelatoriosClosed />} />
                    <Route path="/assinantes/nubank" element={<RelatoriosClosed />} />
                    <Route path="/corretoras" element={<Outlet />}>
                        <Route index element={<Exchanges />} />
                        <Route path='*' element={<NotFound />} />
                        <Route path=":exchange" element={<ExchangeSummary />} />
                    </Route>
                    <Route path="/:Symbol" element={<SymbolMarket />} />
                    <Route path='*' element={<NotFound />} />
                </Route>
            </Routes>
        </>
    );
}

const Layout = () => {
    return (
        <>
            <LivecoinsHeader />
            <NavBar />
            <ScrollToTopNavigation />
            <div className="mainpage">
                <Outlet />
            </div>
            <ScrollToTop />
            <Footer />
        </>
    )
};




export default App;

