import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts'
import { doFetchJson } from "../utils/api";
import { Spinner } from 'react-bootstrap';

const ComboChart = (props) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [options, setOptions] = useState({})
  const [chartseries, setChartSeries] = useState([{
    data: [],
    name: "Volume 24h",
    type: "area"
  }]);

  useEffect(() => {
    const fetchChart = () => {
      fetch((process.env.REACT_APP_API_URL) + '/v1/exchanges/volchart/' + props.exchange)
        .then(res => doFetchJson(res))
        .then(response => {
          var volchart = response.map(x => ({
            'x': x.Time,
            'y': x.QuoteVolume
          }));
          setChartSeries([{
            name: "Volume 24h",
            type: "area",
            data: volchart
          }]);
          setOptions({
            dataLabels: {
              enabled: false
            },
            markers: {
              size: 0
            },
            chart: {
              animations: {
                enabled: false,
              },
              zoom: {
                enabled: false},
              type: 'area',
              height: 'auto',
              background: 'transparent',
              toolbar:{show:false},
            },
            grid:{show:false},
            colors: [
              (volchart[0].y <= volchart[volchart.length - 1].y) ? '#1eb980' : '#C70039'
            ],
            stroke: {
              width: 2,
            },
            yaxis:{show:false},
            xaxis: {
              show:false,
              tooltip: {
                enabled: false,
              },
              type: 'datetime',
              axisTicks: {
                color: "#f5f7ff"
              },
              axisBorder: {
                show: false,
              },
              labels: {
                show:false,
                format: 'dd/MM/yyyy',
              }
            },
            tooltip: {
              enabled: true,
              x: {
                show: true,
              },
              marker: {
                show: false
              },
              y: {
                title: {
                  formatter: (seriesName) => seriesName,
                },
                formatter: function (value) {
                  return (value > 0.1) ? value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ("R$ " + value.toLocaleString('pt-BR', { style: 'decimal', maximumSignificantDigits: 8 }))
                }
              },
            }
          })
          setIsLoaded(true)
        })
        .catch((error) => {
          setError(error)
          setIsLoaded(true)
        })
    };
    setIsLoaded(false); fetchChart()
  }, [props.exchange]);


  if (error) {
    return <>{console.log(error.message+ " - Exchange Volume Chart")}</>;
  } else if (!isLoaded || chartseries === undefined) {
    return <div className="text-center mt-5" style={{ height: "130px" }}><Spinner variant="primary" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner><br />Carregando... </div>;
  } else {

    return (
      <div className="text-center" style={{ height: "150px"}} >
      <span className="h6"> Gráfico de Volume (30 dias) </span>
        <Chart options={options} series={chartseries} type="area" height={130} tabIndex="0" />
      </div>
    );
  }
}

export default ComboChart;