import React from 'react';
import Chart from 'react-apexcharts'
import { Container, Spinner, Alert } from 'react-bootstrap';
import { doFetchJson } from '../utils/api';
import RelatoriosHeader from '../components/restricted/RelatoriosHeader-closed.js';
import Error from '../utils/Error.js';
import groupBy from '../utils/groupBy';

class MonthReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      rawreport: [],
      series: [],
    }

    window.Apex = {
      chart: {
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        axisBorder: {
          color: '#bbc7d200',
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '11px',
          },
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        forceNiceScale: true,
        crosshairs: {
          show: false
        },
      },
      noData: {
        text: 'Carregando...'
      },
      grid: {
        padding: {
          left: -30
        },
        strokeDashArray: 2,
      },
    };

  };

  async fetchReport() {
    var symbol = this.props.symbol.split("=").pop();
    await fetch((process.env.REACT_APP_API_URL) + '/v1/restricted/monthreport' + this.props.symbol + '&month=' + this.props.month + '&year=' + this.props.year)
      .then(response => doFetchJson(response, { notFound: "Não foram encontrados dados mensais para os parâmetros informados!" }))
      .then(response => {
        var xaxistimeUTC = response.map((value) => value.Time).filter((value, index, _arr) => (_arr.indexOf(value) === index));
        var xaxistime = xaxistimeUTC.map((value) => (new Date(value.toString().replace("T00:00:00Z", "T04:00"))).toISOString());
        var monthreport = groupBy(response, "Exchange", xaxistimeUTC);
        var rawreport = response;
        this.setState({
          isLoaded: true,
          rawreport: rawreport,
          series: monthreport,
          xaxistime: xaxistime,
          xaxistimeUTC: xaxistimeUTC,
          options: {
            plotOptions: {
              bar: {
                borderRadius: 5,
                columnWidth: '45%',
              }
            },
            chart: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              type: 'bar',
              height: 350,
              stacked: true,
              toolbar: {
                show: true,
                export: {
                  csv: {
                    filename: "mercadocripto-" + symbol + "-" + this.props.year + "" + this.props.month,
                    columnDelimiter: ';',
                    headerCategory: 'Data',
                    dateFormatter(timestamp) {
                      return new Date(timestamp).toLocaleDateString()
                    }
                  },
                  svg: {
                    filename: "mercadocripto-" + symbol + "-" + this.props.year + "" + this.props.month,
                  },
                  png: {
                    filename: "mercadocripto-" + symbol + "-" + this.props.year + "" + this.props.month,
                  }
                }
              },
            },
            theme: {
              mode: 'light',
              palette: 'palette2',
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            legend: {
              width: '150',
              fontSize: '10px',
              position: 'right',
              offsetY: 40
            },
            stroke: {
              width: 0.5, curve: 'smooth', lineCap: "butt", colors: ['#fff'],
            },

            tooltip: {
              shared: false, followCursor: true,
              marker: {
                show: true,
              },
              x: {
                show: true,
                format: 'dd/MM/yyyy',
              },
              y: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                  return parseFloat(value.toFixed(2)).toLocaleString('pt-BR') + " " + symbol.slice(0, -3)
                },
              }
            },
            crosshairs: {
              show: false,
            },
            xaxis: {
              tickPlacement: 'between',
              type: 'datetime',
              categories: xaxistime,
            },
            yaxis: {
              tickAmount: 4,
              decimalsInFloat: 0,
              labels: {
                offsetX: -30,
                minWidth: 90,
                style: {
                  colors: '#737980',
                }
              },
            },
          }
        },
        )
      }, (error) => {
        this.setState({
          error,
          isLoaded: true,
        })
          ;
      });
  }

  componentDidMount() {
    this.fetchReport();
  }

  componentDidUpdate(prevProps) {
    if (this.props.symbol !== prevProps.symbol) {
      this.setState({
        isLoaded: false,
        error: null
      })
      this.fetchReport();
    }
    else if (this.props.month !== prevProps.month) {
      this.setState({
        isLoaded: false,
        error: null
      })
      this.fetchReport();
    }
    else if (this.props.year !== prevProps.year) {
      this.setState({
        isLoaded: false,
        error: null
      })
      this.fetchReport();
    }
    else { }
  }

  render() {
    const { error, rawreport, isLoaded, series, xaxistime, xaxistimeUTC } = this.state;
    const yesterday = new Date(Date.now() - 86400000)

    if (error) {
      return <Error error={error} />
    } else if (new Date(this.props.year, this.props.month - 1, 1).getTime() >= yesterday) {
      return (
        <Container className="p-5">
          <Alert variant="warning" className="bd-callout p-4 border-warning">
            <Alert.Heading>Esses dados ainda não estão disponíveis.</Alert.Heading>
            <p>Somente os dados anteriores à data atual estão disponíveis para consulta. Favor escolher outra data.<br />
              Se você acha que essa mensagem é um erro, por gentileza, entre em contato.</p>
            <hr />
            <div>
              <a href='mailto:mercadocripto@livecoins.com.br' title="Contato" className="btn btn-lg btn-dark">E-mail</a>
            </div>
          </Alert>
        </Container>
      )
    } else if (!isLoaded) {
      return <Container className="text-center p-5"><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner><p className="fs-5">Carregando, por favor aguarde...</p></Container>;
    } else {
      var symbol = this.props.symbol.split("=").pop();
      return (
        <>
          <RelatoriosHeader rawreport={rawreport} xaxistime={xaxistime} xaxistimeUTC={xaxistimeUTC} symbol={symbol} series={series} month={true} />
          <Container className="pt-4">
            <Chart options={this.state.options} series={series} type="bar" height={350} />
          </Container>
        </>
      );
    }
  }
}
export default MonthReport;