import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Button, Spinner, Placeholder } from 'react-bootstrap';
import CoinsTable from './components/MainTable'
import { Helmet } from "react-helmet";
import { doFetchJson } from "./utils/api";

const SymbolTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [coinsfull, setCoinsFull] = useState([]);
  const [coins, setCoins] = useState([]);
  const [last, setLast] = useState([]);
  const [chart, setChart] = useState([]);

  const getLast = useCallback(async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/v1/ticker/last');
      const result = await doFetchJson(response);
      let tempState = [...coins]
      setCoins(tempState.map(x => Object.assign(x, result.find(y => y.Symbol === x.Symbol))));
      setLast(result);
      Array.from(document.getElementsByClassName('toupdate')).forEach((el) => el.classList.add('updated'));
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  }, [coins]);

  const getCoins = () => {
    Promise.all([fetch(process.env.REACT_APP_API_URL + "/v1/ticker/market-data"), fetch(process.env.REACT_APP_API_URL + "/v1/ticker/chart")])
      .then(([res1, res2]) => {
        return Promise.all([doFetchJson(res1), res2])
      })
      .then(([res1, res2]) => {
        setCoinsFull(res1.sort((a, b) => parseFloat(b.QuoteVolume) - parseFloat(a.QuoteVolume)));
        setCoins(res1.sort((a, b) => parseFloat(b.QuoteVolume) - parseFloat(a.QuoteVolume)).slice(0, 10));
        setChart(res2);
      },
        // handle errors
        (error) => {
          console.error('Error fetching data:', error);
        }
      );
  };


  useEffect(() => {
    getCoins();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getLast();
    }, 10000); // Fetch data every 10 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [getLast]); // Empty dependency array to run only once on mount


  const LoadMore = (newState) => {
    setIsLoading(true);
    setTimeout(() => {
      setCoins(newState);
      setIsLoaded(true);
      setIsLoading(false);
    }, 10);
  };


  const organization = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "MercadoCripto",
    "description": "O MercadoCripto fornece uma análise fundamental do mercado de criptomoedas no Brasil. Alem do preço do Bitcoin e todas as altcoins do mercado brasileiro de criptomoedas, todas as corretoras são ranqueadas por volume. Acesso a gráficos, pares de negociação, e dados históricos de todas as corretoras do país.",
    "url": "https://www.mercadocripto.livecoins.com.br/",
    "logo": "https://www.mercadocripto.livecoins.com.br/mercadocripto-logo.png",
  };

  const FAQPage = {
    "@context": "http://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Qual o volume diário de criptomoedas negociadas no Brasil?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "O volume total de criptomoedas negociados nas últimas 24 horas apenas no Brasil é de " + coinsfull.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + ", somando todos os pares com BRL."
        }
      },
    ]
  }

  return (
    <>
      <Helmet>
        <title>MercadoCripto · Preço, volume e gráficos do mercado de criptomoedas brasileiro</title>
        <meta name="description" content="O MercadoCripto fornece uma análise fundamental do mercado de criptomoedas no Brasil. Alem do preço do Bitcoin e todas as altcoins do mercado brasileiro de criptomoedas, todas as corretoras são ranqueadas por volume. Acesso a gráficos, pares de negociação, e dados históricos de todas as corretoras do país." />
        <script type="application/ld+json">{JSON.stringify(organization)}</script>
        <script type="application/ld+json">{JSON.stringify(FAQPage)}</script>
      </Helmet>
      <div className="bg-light py-4">
        <Container className="py-5">
          <h1 className="h1 display-5 fw-medium text-center">Mercado Cripto</h1>
          <h2 className="text-center fw-normal h4 d-none d-md-block">Portal de informações do mercado de criptomoedas brasileiro.</h2>
          <Row className="py-3 g-3 text-center justify-content-center fw-bold text-uppercase  text-muted">
            <Col md="auto">
              <h3 className="h6">Volume negociado (24h)</h3>
              <span className="h5">{coinsfull.reduce((a, e) => a + e.QuoteVolume, 0) === 0 ? <Placeholder as="p" animation="glow"><Placeholder className="rounded" xs={7} /></Placeholder> : coinsfull.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
            </Col>
            <Col md="auto" className="text-center pt-1">
              <h3 className="h6">Criptomoedas</h3>
              <span className="h5">{coinsfull.length === 0 ? <Placeholder as="p" animation="glow"><Placeholder className="rounded" xs={2} /></Placeholder> : coinsfull.length}</span>
            </Col>
          </Row>
          <div className='text-center'>
            <a href='/assine' title="Assine Nossos Serviços" className="btn btn-lg btn-primary fw-medium">Conheça nossas soluções</a>
          </div>
        </Container>
      </div>
      <Container className="text-center my-5">
        <CoinsTable data={coins} last={last} chart={chart} />
        {!isLoading && !isLoaded ? <Button variant="outline-primary" className="btn-border-2 mb-2" onClick={() => LoadMore(coinsfull)}>Carregar mais</Button>
          : (!isLoading && isLoaded) ? '' : <Button variant="primary" className="mb-2" style={{ border: "2px solid" }} id="loadingmore" disabled><Spinner as="span" animation="border" size="sm" role="status" /> Carregando... </Button>}
      </Container>
    </>
  );
};

export default SymbolTable;